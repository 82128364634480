import { BLOCKS } from '@contentful/rich-text-types';
import type {
  Document as ContentfulDocument,
  TopLevelBlock,
} from '@contentful/rich-text-types';
import { logoNames, logos, iconNames } from 'geist/symbols';
import type { LogoName, IconName } from 'geist/symbols';
import type { PixelIconName } from 'geist/symbols/pixel/name-to-icon';
import { pixelIconNames } from 'geist/symbols/pixel/name-to-icon';
import { getAssetURL } from '../utils/get-asset-url';
import {
  EditableContentfulDocument,
  EditableModeDependentMedia,
  EditableString,
  parseContentfulObject,
  ParseError,
  optional,
  parseEntryLike,
  parseNumber,
  parseArrayOfType,
  parseObjectOfType,
  parseString,
  parseBoolean,
} from '.';
import type { Parser } from '.';

export type { ContentfulDocument };

/**
 * This is a little "fake" -- it does make sure the top-level object is correct,
 * but it doesn't parse anything below the `content` field other than making sure `content` is an array.
 */
export const parseContentfulDocument: Parser<ContentfulDocument> = (value) => {
  return parseObjectOfType(value, {
    data: (data) => parseObjectOfType(data, {}),
    nodeType: (nodeType) => {
      const str = parseString(nodeType);
      if (str !== BLOCKS.DOCUMENT) {
        throw new Error(`Unknown nodeType: ${str}`);
      }
      return str as typeof BLOCKS.DOCUMENT;
    },
    content: (content) =>
      parseArrayOfType(content, (node) => node as TopLevelBlock),
  });
};

export interface ContentfulImage {
  title: string;
  description: string | undefined;
  url: string;
  width: number;
  height: number;
  fileName: string;
  contentType: string;
}

export const parseImage: Parser<ContentfulImage> = (value) => {
  const { fields } = parseEntryLike(value);
  const withKnownValues = parseObjectOfType(fields, {
    title: parseString,
    description: optional(parseString),
    file: (file) => {
      return parseObjectOfType(file, {
        url: parseString,
        details: (details) => {
          return parseObjectOfType(details, {
            size: parseNumber,
            image: (image) => {
              return parseObjectOfType(image, {
                width: parseNumber,
                height: parseNumber,
              });
            },
          });
        },
        fileName: parseString,
        contentType: parseString,
      });
    },
  });

  const url = getAssetURL(withKnownValues.file.url, 'image');

  return {
    title: withKnownValues.title,
    description: withKnownValues.description,
    url,
    width: withKnownValues.file.details.image.width,
    height: withKnownValues.file.details.image.height,
    fileName: withKnownValues.file.fileName,
    contentType: withKnownValues.file.contentType,
  };
};

export interface ModeDependentMedia {
  title: string | undefined;
  lightVersion: ContentfulImage | ContentfulVideo;
  darkVersion: ContentfulImage | ContentfulVideo;
  isScreenshot: boolean | undefined;
  noPadding: boolean | undefined;
}

export const parseModeDependentMedia: Parser<ModeDependentMedia> = (value) => {
  const isVideo =
    // @ts-expect-error This should always exist, contentful is not typed
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    value?.fields?.lightVersion?.fields?.file?.contentType?.startsWith(
      'video',
    ) as boolean;

  const mediaParser = isVideo ? parseVideo : parseImage;

  return parseContentfulObject(value, 'data', {
    title: optional(parseString),
    lightVersion: mediaParser,
    darkVersion: mediaParser,
    isScreenshot: optional(parseBoolean),
    noPadding: optional(parseBoolean),
  });
};

export function parseBrandLogo(value: unknown): LogoName {
  const key = parseString(value);
  if (!Object.prototype.hasOwnProperty.call(logos, key)) {
    throw new ParseError({
      type: 'leaf',
      message: `Expected a brand logo, but got ${String(
        value,
      )},  (${typeof value})`,
    });
  }
  return value as LogoName;
}

export const parseLogoName: Parser<LogoName> = (value) => {
  const key = parseString(value);
  if (!logoNames.includes(key as LogoName)) {
    throw new ParseError({
      type: 'leaf',
      message: `Expected an logo name, but got ${String(
        value,
      )},  (${typeof value})`,
    });
  }
  return value as LogoName;
};

export const parsePixelIconName: Parser<PixelIconName> = (value) => {
  const key = parseString(value);
  if (!pixelIconNames.includes(key as PixelIconName)) {
    throw new ParseError({
      type: 'leaf',
      message: `Expected a pixel icon name, but got ${String(
        value,
      )},  (${typeof value})`,
    });
  }
  return value as PixelIconName;
};

export const parseIconName: Parser<IconName> = (value) => {
  const key = parseString(value);
  if (!iconNames.includes(key as IconName)) {
    // throw new ParseError({
    //   type: 'leaf',
    //   message: `Expected an icon name, but got ${String(
    //     value,
    //   )},  (${typeof value})`,
    // });
  }
  return value as IconName;
};

export const parseEditableString: Parser<EditableString> = (value) => {
  const parsed = parseString(value);
  return new EditableString(parsed);
};

export const parseEditableModeDependentMedia: Parser<
  EditableModeDependentMedia
> = (value) => {
  const parsed = parseModeDependentMedia(value);
  return new EditableModeDependentMedia(parsed);
};

export const parseEditableContentfulDocument: Parser<
  EditableContentfulDocument
> = (value) => {
  const parsed = parseContentfulDocument(value);
  return new EditableContentfulDocument(parsed);
};

export interface BlogCodeSandboxAsset {
  title: string;
  description: string | undefined;
  url: string;
}

export const parseBlogSandboxAsset: Parser<BlogCodeSandboxAsset> = (value) => {
  const { fields } = parseEntryLike(value);
  const withKnownValues = parseObjectOfType(fields, {
    file: (file) => {
      return parseObjectOfType(file, {
        url: parseString,
      });
    },
  });

  const url = getAssetURL(withKnownValues.file.url, 'image');

  return {
    title: '',
    description: '',
    url,
  };
};

export const parseTableOfContentsOpts: Parser<
  'Disabled' | 'Default' | 'Full'
> = (value) => {
  const parsed = parseString(value);
  if (parsed !== 'Disabled' && parsed !== 'Default' && parsed !== 'Full') {
    throw new ParseError({
      type: 'leaf',
      message: `Expected 'Disabled', 'Default', or 'Full', but got ${String(
        value,
      )},  (${typeof value})`,
    });
  }
  return parsed as 'Disabled' | 'Default' | 'Full';
};

export interface ContentfulVideo {
  title: string;
  description: string | undefined;
  file: {
    url: string;
    details: {
      size: number;
    };
    fileName: string;
    contentType: string;
  };
}

export const parseVideo: Parser<ContentfulVideo> = (value) => {
  const { fields } = parseEntryLike(value);
  const withKnownValues = parseObjectOfType(fields, {
    title: parseString,
    description: optional(parseString),
    file: (file) => {
      return parseObjectOfType(file, {
        url: parseString,
        details: (details) => {
          return parseObjectOfType(details, {
            size: parseNumber,
          });
        },
        fileName: parseString,
        contentType: parseString,
      });
    },
  });

  const url = getAssetURL(withKnownValues.file.url, 'video');

  return {
    title: withKnownValues.title,
    description: withKnownValues.description,
    file: {
      url,
      details: {
        size: withKnownValues.file.details.size,
      },
      fileName: withKnownValues.file.fileName,
      contentType: withKnownValues.file.contentType,
    },
  };
};

export interface MobileAndModeDependentMediaImage {
  title: string | undefined;
  lightMobileVersion: ContentfulImage;
  darkMobileVersion: ContentfulImage;
  lightDesktopVersion: ContentfulImage;
  darkDesktopVersion: ContentfulImage;
  isScreenshot: boolean | undefined;
  noPadding: boolean | undefined;
}

export interface MobileAndModeDependentMediaVideo {
  title: string | undefined;
  lightMobileVersion: ContentfulVideo;
  darkMobileVersion: ContentfulVideo;
  lightDesktopVersion: ContentfulVideo;
  darkDesktopVersion: ContentfulVideo;
}

export const parseMobileAndModeDependentMediaImage: Parser<
  MobileAndModeDependentMediaImage
> = (value) => {
  return parseContentfulObject(value, 'title', {
    title: optional(parseString),
    lightMobileVersion: parseImage,
    darkMobileVersion: parseImage,
    lightDesktopVersion: parseImage,
    darkDesktopVersion: parseImage,
    isScreenshot: optional(parseBoolean),
    noPadding: optional(parseBoolean),
  });
};

export const parseMobileAndModeDependentMediaVideo: Parser<
  MobileAndModeDependentMediaVideo
> = (value) => {
  return parseContentfulObject(value, 'title', {
    title: optional(parseString),
    lightMobileVersion: parseVideo,
    darkMobileVersion: parseVideo,
    lightDesktopVersion: parseVideo,
    darkDesktopVersion: parseVideo,
  });
};
